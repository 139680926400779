<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
  emits: ['loaded'],
  setup(_, { emit, slots }) {
    const target = ref(null)
    const isVisible = ref(false)
    const observer = useIntersectionObserver(
      target,
      ([{ isIntersecting }]) => {
        if (isIntersecting && !isVisible.value) {
          emit('loaded')
          isVisible.value = true
          observer.stop()
        }
      },
      {
        immediate: true,
      }
    )

    return () => {
      if (isVisible.value) {
        return slots.default ? slots.default() : h('div')
      }

      return h('div', { ref: target })
    }
  },
})
</script>
